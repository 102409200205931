define("iosxpert-web/components/sidebar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FtsoS8hL",
    "block": "[[[10,0],[14,1,\"navigation-bar\"],[12],[1,\"\\n\"],[10,0],[14,0,\"flex lg:flex-shrink-0\"],[12],[1,\"\\n  \"],[10,0],[14,\"data-test-id\",\"sidebar\"],[15,0,[29,[\"flex flex-col transform \",[52,[30,0,[\"collapsed\"]],\"w-12\",\"w-64\"]]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-col h-0 flex-1 border-r border-gray-300 bg-gray-100\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"flex-1 flex overflow-y-auto overflow-x-hidden\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"flex-1 flex flex-col overflow-y-auto\"],[12],[1,\"\\n          \"],[10,\"nav\"],[14,0,\"mt-5 flex-1\"],[14,\"aria-label\",\"Sidebar\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"flex md:hidden mx-auto p-4\"],[12],[1,\"\\n              \"],[8,[39,1],[[24,0,\"object-cover h-20 w-full\"]],[[\"@image\"],[\"/assets/images/iOWeb_BETA.png\"]],null],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"px-2 space-y-2 mt-8 md:mt-0\"],[12],[1,\"\\n              \"],[8,[39,2],null,[[\"@sidebar\",\"@title\",\"@icon\",\"@route\"],[[30,0],\"People\",\"user-friends\",\"main.people\"]],null],[1,\"\\n              \"],[8,[39,2],null,[[\"@sidebar\",\"@title\",\"@icon\",\"@route\"],[[30,0],\"Companies\",\"building\",\"main.companies\"]],null],[1,\"\\n              \"],[8,[39,2],null,[[\"@sidebar\",\"@title\",\"@icon\",\"@route\"],[[30,0],\"Projects\",\"hammer\",\"main.projects\"]],null],[1,\"\\n              \"],[8,[39,2],null,[[\"@sidebar\",\"@title\",\"@icon\",\"@route\"],[[30,0],\"Tasks\",\"tasks\",\"main.tasks\"]],null],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[39,3],null,[[\"@sidebar\"],[[30,0]]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,4],null,[[\"@firstName\",\"@lastName\",\"@sidebar\"],[\"Paul\",\"Presenter\",[30,0]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"utils/image\",\"sidebar/item\",\"sidebar/collapsed\",\"sidebar/user\"]]",
    "moduleName": "iosxpert-web/components/sidebar/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});